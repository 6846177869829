import { atom } from "jotai";
import { BoardCache, MinimalBoard } from "shared/datamodel/schemas";

export const boardCacheAtom = atom<BoardCache>({});

export const boardsCountAtom = atom<number | null>(null);

export const RECENT_BOARDS_SECTION_ID = "recent-boards";
export const MY_BOARDS_SECTION_ID = "my-boards";
export const SHARED_WITH_ME_SECTION_ID = "shared-with-me";

interface BoardsSection {
  boards: MinimalBoard[];
  id: string;
}
interface BoardsSections {
  [key: string]: BoardsSection;
}

const initialState: BoardsSections = {
  [MY_BOARDS_SECTION_ID]: { boards: [], id: MY_BOARDS_SECTION_ID },
  [RECENT_BOARDS_SECTION_ID]: { boards: [], id: RECENT_BOARDS_SECTION_ID },
  [SHARED_WITH_ME_SECTION_ID]: { boards: [], id: SHARED_WITH_ME_SECTION_ID },
};

export const boardsSectionsAtom = atom<BoardsSections>(initialState);
