import consts, { TypeCanvasElement } from "shared/consts";

const elementsValues = Object.values(consts.CANVAS_ELEMENTS).reduce((acc: Record<string, false>, element: string) => {
  acc[`canvas-element-${element}`] = false;
  return acc;
}, {}) as Record<`canvas-element-${TypeCanvasElement}`, false>;

export const DefaultFeatureFlagsValues = {
  "test-flag": false,
  "test-variant": { temp: "temp" },
  ...elementsValues,
  "org-chart-walkthrough": false,
  "allow-table-creation": false,
  "per-seat-license": null,
  "restrict-free-canvas-elements": { elementsNumber: 10 },
  "cursor-visibility-control": false,
  "orgchart-filters-column-dropdown": false,
  "orgchart-department-enabled": false,
  "gantt-monday-integration-items": false,
  "home-page-refactor": false,
  "onboarding-choose-template": false,
  "filter-selection": false,
  "gantt-monday-dependency-column": false,
  "new-shape-menu": false,
  "stickynote-on-double-click": false,
  "new-zoom-behavior": false,
  "gantt-monday-split-rows": false,
  "restricted-columns": false,
  "reduce-org-chart-loading-time": false,
  "ab-test-invite-teammates-to-board-popup": false,
  "input-bubbles-field-v2": false,
  "zoom-to-fit-new-elements": false,
  "orgchart-hover-behavior": false,
  "gantt-monday-sub-items-support": false,
  "element-data-layer": false,
  "gantt-monday-timeline-column": false,
  "disable-task-assignee": false,
  "invite-users-autocomplete": false,
};

export type FeatureFlagsKeys = keyof typeof DefaultFeatureFlagsValues | `canvas-element-${TypeCanvasElement}`;

export type FeatureFlagValue<K extends FeatureFlagsKeys> = typeof DefaultFeatureFlagsValues[K];

export type FeatureFlagsValues = typeof DefaultFeatureFlagsValues;
