import { stringify, parse } from "superjson";

type IndexedDbRequest = {
  objectStore: string;
};

export type IndexedDbDeleteRequest = IndexedDbRequest & {
  key: string;
};

export type IndexedDbGetRequest = IndexedDbRequest & {
  key: string;
  index?: string;
};

export type IndexedDbAddRequest = IndexedDbRequest & {
  value: any;
};

type LocalStorageItem<T> = {
  value: T;
  expirationTimeStamp: Date;
};

// Local storage
export function setLocalStorageItem<T>(key: string, value: T, ttlInDays: number = 365) {
  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const timestamp = new Date();
  const expirationTimeStamp = new Date();
  expirationTimeStamp.setHours(timestamp.getHours() + 24 * ttlInDays);
  const item: LocalStorageItem<T> = {
    value,
    expirationTimeStamp,
  };

  const stringValue = stringify(item);

  localStorage.setItem(key, stringValue);
}

export function isLocalStorageAvailable() {
  try {
    return typeof localStorage !== "undefined";
  } catch {
    return false;
  }
}

export function getLocalStorageItem<T>(key: string): T | null {
  try {
    const stringItemValue = localStorage.getItem(key);
    if (!stringItemValue) return null;

    const item = parse<LocalStorageItem<T>>(stringItemValue);
    if (Date.now() > item.expirationTimeStamp.getTime()) {
      localStorage.removeItem(key);
      return null;
    }

    return item.value;
  } catch {
    return null;
  }
}

export function addToLocalStorageArray<T>(arrayName: string, value: T) {
  const existingObject: T[] = getLocalStorageItem(arrayName) ?? [];
  if (!existingObject.includes(value)) {
    existingObject.push(value);
    setLocalStorageItem(arrayName, existingObject, 1);
  }
}

export function removeItemFromLocalStorage(key: string) {
  localStorage.removeItem(key);
}
