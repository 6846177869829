import { Action, DispatchFunc } from "frontend/state/actions";
import { Board, BoardPermission, BoardState as BoardStateEnum } from "shared/datamodel/schemas/board";
import {
  createBoard as internalCreateBoard,
  deleteBoard as internalDeleteBoard,
  editBoard as internalEditBoard,
  moveBoard as internalMoveBoard,
  duplicateBoard as internalDuplicateBoard,
  fetchBoard,
  getBoards,
  getPasswordProtectedBoard,
  getBoardsCount,
} from "../api";
import { BlankCanvasId } from "shared/datamodel/schemas";
import tracking from "frontend/tracking";
import { iframeHasSessionToken } from "utils/url-utils";
import { getAccessPermissionToMondayBoard } from "./monday-api-service";

export async function loadBoards(dispatch: DispatchFunc, isPrivateByCreator: boolean = false) {
  dispatch({ type: Action.LoadingBoards, payload: null });
  try {
    const boards = await getBoards({ isPrivateByCreator });
    dispatch({ type: Action.UpdatedBoards, payload: boards });
  } catch {
    dispatch({ type: Action.FailedLoadingBoards, payload: null });
  }
}

export async function deleteBoard(dispatch: any, board: Board) {
  dispatch({ type: Action.DeleteBoard, payload: board.documentId });
  try {
    await internalDeleteBoard(board.documentId);
  } catch {
    dispatch({ type: Action.FailedDeleteBoard, payload: board });
  }
}

export async function duplicateBoard(dispatch: any, board: Board) {
  const { board: newBoard, contents } = await internalDuplicateBoard(board.documentId);
  dispatch && dispatch({ type: Action.CreatedBoard, payload: newBoard });
  return { documentId: newBoard.documentId, contents };
}

export async function editBoard(dispatch: any, board: Board, oldBoard: Board) {
  dispatch({ type: Action.EditedBoard, payload: board });
  try {
    const res = await internalEditBoard(board);
    return res;
  } catch {
    dispatch({ type: Action.FailedEditBoard, payload: oldBoard });
  }
}

export async function moveBoard(dispatch: any, board: Board, oldBoard: Board) {
  dispatch({ type: Action.EditedBoard, payload: board });
  try {
    const res = await internalMoveBoard(board);
    return res;
  } catch {
    dispatch({ type: Action.FailedEditBoard, payload: oldBoard });
  }
}

export async function createBoard(
  name: string,
  state: BoardStateEnum | null,
  templateIdOrAlias: string | null,
  projectId: number | null,
  teamId: number | null,
  dispatch?: any
): Promise<Board> {
  templateIdOrAlias = templateIdOrAlias !== BlankCanvasId ? templateIdOrAlias : null;
  const board = await internalCreateBoard(name, templateIdOrAlias, state ?? BoardStateEnum.default, projectId, teamId);
  console.log("internalCreateBoard", { board, state, createdFromTemplateId: templateIdOrAlias });
  dispatch && dispatch({ type: Action.CreatedBoard, payload: board });
  return board;
}

export async function createBoardNew(
  name: string,
  state: BoardStateEnum | null,
  templateIdOrAlias: string | null,
  projectId: number | null,
  teamId: number | null,
  dispatch?: any,
  isPrivateByCreator: boolean = false
): Promise<Board> {
  templateIdOrAlias = templateIdOrAlias !== BlankCanvasId ? templateIdOrAlias : null;
  const board = await internalCreateBoard(
    name,
    templateIdOrAlias,
    state ?? BoardStateEnum.default,
    projectId,
    teamId,
    null,
    BoardPermission.private,
    isPrivateByCreator
  );
  console.log("internalCreateBoard", { board, state, templateIdOrAlias });
  dispatch && dispatch({ type: Action.CreatedBoard, payload: board });
  return board;
}

export async function loadBoard(
  dispatch: DispatchFunc,
  setBoardsCount: (boardsCount: number) => void,
  documentId: string,
  password?: string,
  isPrivateByCreator: boolean = false,
  freeUser: boolean = false
) {
  dispatch({ type: Action.LoadingSpecificBoard, payload: documentId });
  let data: { board: Board; templateElements: any; authToken?: string };
  if (password) {
    data = await getPasswordProtectedBoard(documentId, password);
  } else {
    data = await fetchBoard(documentId, isPrivateByCreator);
  }

  if (freeUser) {
    const boardsCount = await getBoardsCount();
    setBoardsCount(boardsCount);
  }
  await loadMondayUserBoardAccess(dispatch);
  dispatch({ type: Action.LoadedSpecificBoard, payload: data.board });
  return data;
}

async function loadMondayUserBoardAccess(dispatch: DispatchFunc) {
  if (iframeHasSessionToken()) {
    const mondayBoardAccessPermission = await getAccessPermissionToMondayBoard();
    tracking.trackAnalyticsEvent("canvas_monday_board_access", {
      mondayBoardaccessPermission: mondayBoardAccessPermission,
    });
    dispatch({ type: Action.UpdateMondayUserBoardAccess, payload: mondayBoardAccessPermission });
  }
}
