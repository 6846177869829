import consts from "../../consts";
import { z } from "zod";
import { textEnabledSchema } from "./text-enabled-schema";
import { canvasElementBaseSchema } from "./canvas-element";

export enum MindmapNodeOrientation {
  HORIZONTAL = "horizontal",
  VERTICAL = "vertical",
}

export enum MindmapNodeDirection {
  TRAILING = 0,
  LEADING = 1,
}

export const mindmapNodeSchema = z.object({
  type: z.literal(consts.CANVAS_ELEMENTS.MINDMAP),
  ...canvasElementBaseSchema.shape,
  ...textEnabledSchema.shape,
  x: z.number(),
  y: z.number(),
  width: z.number(),
  height: z.number(),
  rootId: z.string(),
  parentId: z.string().nullish(),
  direction: z.nativeEnum(MindmapNodeDirection).optional(),
  sortIndex: z.number(),
  color: z.string().optional(),
  orientation: z.nativeEnum(MindmapNodeOrientation),
  collapsed: z.boolean(),
});

export type MindmapNodeElement = z.infer<typeof mindmapNodeSchema>;
