import {
  FeatureFlagsKeys,
  DefaultFeatureFlagsValues,
  FeatureFlagValue,
  FeatureFlagsValues,
} from "frontend/hooks/use-feature-flag/flags";
import {
  useFeatureFlagPayload as usePosthogFFPayload,
  useFeatureFlagVariantKey,
  useFeatureFlagEnabled,
  usePostHog,
} from "posthog-js/react";
import posthog from "posthog-js";
import { useMemo } from "react";

export const useFeatureFlag = <K extends FeatureFlagsKeys>(flag: K): FeatureFlagValue<K> => {
  const value = useFeatureFlagEnabled(flag) ?? DefaultFeatureFlagsValues[flag];
  return value as FeatureFlagValue<K>;
};

export const useFeatureFlagPayload = <K extends FeatureFlagsKeys>(flag: K): FeatureFlagValue<K> => {
  const value = usePosthogFFPayload(flag);
  return value as FeatureFlagValue<K>;
};

export const useFeatureFlagVariant = (flag: FeatureFlagsKeys) => {
  return useFeatureFlagVariantKey(flag);
};

/**
 * @deprecated please use useFeatureFlag for reactivity
 */
export const getFeatureFlag = <K extends FeatureFlagsKeys>(flag: K): FeatureFlagValue<K> => {
  const value = posthog.isFeatureEnabled(flag) ?? DefaultFeatureFlagsValues[flag];
  return value as FeatureFlagValue<K>;
};

export const useGetAllFeatureFlags = () => {
  const posehog = usePostHog();
  return useMemo(() => {
    return Object.fromEntries(
      posehog.featureFlags.getFlags().map((flag) => [flag, posehog.featureFlags.getFeatureFlag(flag)])
    ) as unknown as FeatureFlagsValues;
  }, [posehog.featureFlags]);
};
